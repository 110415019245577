import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  computed,
  inject,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { getWindow } from 'ssr-window';
import { SliderContentDirective } from './slider-content.component';

interface Slide {
  image: string;
  alt: string;
  link: string;
}

@Component({
  selector: 'app-slider',
  standalone: true,
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  imports: [CommonModule, RouterModule, SliderContentDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements AfterViewInit, OnDestroy {
  private readonly el = inject(ElementRef);
  private readonly repeat = signal(1);

  @ContentChild(SliderContentDirective) public content?: SliderContentDirective;

  @Input() public speed = 15;

  public readonly items = computed(() => {
    const repeat = this.repeat();

    return Array.from(new Array(repeat * 2));
  });

  public readonly animationSpeed = computed(() => {
    const repeat = this.repeat();
    return `${repeat * this.speed}s`;
  });

  public ngAfterViewInit() {
    setTimeout(() => {
      this.calcWidth();
    }, 50);
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.calcWidth();
  }

  private timer?: number;
  private calcWidth() {
    clearTimeout(this.timer);

    if (!this.el.nativeElement.querySelector('.slide-group')?.getBoundingClientRect) {
      return;
    }

    const slideGroupWidth = this.el.nativeElement.querySelector('.slide-group').getBoundingClientRect().width;
    const slideWidth = this.el.nativeElement.querySelector('.slider').getBoundingClientRect().width;
    if (slideGroupWidth && slideWidth) {
      const newRepeat = Math.floor(slideWidth / slideGroupWidth) + 1;
      if (newRepeat !== this.repeat()) {
        this.repeat.set(newRepeat);
      }
    } else {
      this.timer = getWindow().setTimeout(() => this.calcWidth(), 150);
    }
  }
  public ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
