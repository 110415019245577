import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { effect, inject, Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { of } from 'rxjs';
import { getWindow } from 'ssr-window';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SitemuleAIClient: any;
}
const win = getWindow();

interface AA {
  onReady: (cal: () => void) => void;
  onMaximize: (cal: () => void) => void;
  onMinimize: (cal: () => void) => void;
  onClose: (cal: () => void) => void;
  onMessageLinkClick: (cal: (link: string) => void) => void;
  minimize: () => Promise<void>;
  maximize: () => Promise<void>;
  addMessage: (message: string) => Promise<void>;
  askChatGPT: (prompt: string) => Promise<string>;
  addSuggestedMessages: (messages: string[]) => Promise<void>;
  startNewConversation: () => Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class SitemuleAIService {
  private readonly http = inject(HttpClient);
  private readonly isBrowser = isPlatformBrowser(this.platformId);
  private injectingScript = false;
  private injectingIframe = false;
  public assistantStatus = signal<'hidden' | 'icon' | 'expanded-small' | 'expanded-large'>('hidden');
  public suggestions = signal<string[]>([]);
  private readonly script = (() => {
    const script = document.createElement('script');
    script.src = `https://test-assistant.sitemule.com/client.js?bust=${Date.now()}`;
    return script;
  })();

  public ai!: AA;

  private firstEffectProcessed = false;
  private eff = effect(() => {
    const assistantStatus = this.assistantStatus();
    if (!this.firstEffectProcessed) {
      this.firstEffectProcessed = true;
      return;
    }
    localStorage.setItem('sitemule-ai-assistant-status', assistantStatus);
  });

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  private injectScript(): Promise<void> {
    return new Promise(res => {
      // If SitemuleAIClient exists, it means script is already injected
      if (typeof SitemuleAIClient !== 'undefined') {
        res();
        return;
      }
      if (this.injectingScript) {
        throw `Double injection`;
      }
      this.injectingScript = true;
      this.script.onload = () => {
        this.injectingScript = false;
        res();
      };
      win.document.head.append(this.script);
    });
  }

  private injectIframe(el: HTMLElement): Promise<void> {
    return new Promise(res => {
      if (this.injectingIframe) {
        throw `Double injection`;
      }
      if (this.ai) {
        res();
        return;
      }
      this.injectingIframe = true;

      this.ai = new SitemuleAIClient({
        organization: 'eriksen',
        targetElement: el,
      });

      this.ai.onReady(() => {
        this.injectingIframe = false;
        res();
      });
    });
  }

  private addListeners() {
    this.onMaximize(() => {
      this.assistantStatus.set('expanded-large');
    });
    this.onMinimize(() => {
      this.assistantStatus.set('expanded-small');
    });
    this.onClose(() => {
      this.assistantStatus.set('icon');
    });
  }

  public init(el: HTMLElement): Promise<void> {
    return this.injectScript()
      .then(() => {
        return this.injectIframe(el);
      })
      .then(() => {
        this.addListeners();
      })
      .then(() => {
        const initialState = (() => {
          const storedStatus = localStorage.getItem('sitemule-ai-assistant-status');
          if (storedStatus === 'icon' || storedStatus === 'expanded-small' || storedStatus === 'expanded-large') {
            return storedStatus;
          }
          return 'icon';
        })();
        if (initialState === 'icon') {
          this.showIcon();
          return;
        }
        if (initialState === 'expanded-small') {
          this.expandSmall();
          return;
        }
        if (initialState === 'expanded-large') {
          this.expandLarge();
          return;
        }
      });
  }

  private _onReady(fn: () => void, counter = 1) {
    if (counter >= 500) {
      throw `Sitemule AI took too long time to be ready`;
    }
    if (this.ai && !this.injectingIframe) {
      fn();
    } else {
      win.setTimeout(() => {
        this._onReady(fn, counter + 1);
      }, 100);
    }
  }

  public onReady(): Promise<void> {
    return new Promise(res => {
      this._onReady(() => {
        res();
      });
    });
  }
  public onMaximize(onMaximize: () => void) {
    return this.ai.onMaximize(onMaximize);
  }

  public onMinimize(onMinimize: () => void) {
    return this.ai.onMinimize(onMinimize);
  }

  public onClose(onClose: () => void) {
    return this.ai.onClose(onClose);
  }

  public onMessageLinkClick(onMessageLinkClick: (link: string) => void) {
    return this.ai.onMessageLinkClick(onMessageLinkClick);
  }

  public showChatView() {
    const assistantStatus = this.assistantStatus();
    if (assistantStatus === 'hidden' || assistantStatus === 'icon') {
      this.expandSmall();
    }
  }
  public hide(): Promise<void> {
    return new Promise(res => {
      this.assistantStatus.set('hidden');
      res();
    });
  }

  public showIcon(): Promise<void> {
    return new Promise(res => {
      this.assistantStatus.set('icon');
      res();
    });
  }

  public expandSmall(): Promise<void> {
    return this.onReady().then(() => {
      return this.ai.minimize();
    });
  }

  public expandLarge(): Promise<void> {
    return this.onReady().then(() => {
      return this.ai.maximize();
    });
  }

  public sendMessage(message: string): Promise<void> {
    return this.onReady().then(() => {
      return this.ai.addMessage(message);
    });
  }
  public addSuggestedMessages(suggestions: string[]): Promise<void> {
    this.suggestions.set(suggestions);
    return this.onReady().then(() => {
      return this.ai.addSuggestedMessages(suggestions);
    });
  }

  public startNewConversation(): Promise<void> {
    return this.onReady().then(() => {
      return this.ai.startNewConversation();
    });
  }

  public askChatGPT(prompt: string) {
    return this.onReady().then(() => {
      return this.ai.askChatGPT(prompt);
    });
  }

  public getQuestions(seoURL: string, title: string) {
    return of([`Where can we use ${title}`, `List related products of ${title}`]);
    return this.http.get<string[]>(`@api/cms/prd/listQuestionsBySeoUrl/${seoURL}`);
  }
}
