import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ToastModule } from '@sitemule/ng-components/components/toast';
import { getWindow } from 'ssr-window';
import { SliderContentDirective } from './components/slider/slider-content.component';
import { SliderComponent } from './components/slider/slider.component';
import { SitemuleAIService } from './services/sitemule-ai.service';

const win = getWindow();

const isSameDomain = (url1: string) => {
  return url1.startsWith(`${location.protocol}//${location.host}`);
};
const getRelativePath = (url1: string) => {
  return url1.replace(`${location.protocol}//${location.host}`, '');
};
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule, SliderComponent, SliderContentDirective],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly sitemuleAIService = inject(SitemuleAIService);
  private readonly router = inject(Router);
  private readonly el = inject(ElementRef);
  public readonly assistantStatus = this.sitemuleAIService.assistantStatus;
  public readonly suggestions = this.sitemuleAIService.suggestions;
  public readonly assistantIconExpanded = signal(true);

  public readonly width = computed(() => {
    const suggestions = this.suggestions();
    return `${Math.max(...suggestions.map(s => s.length)) * 8}px`;
  });

  private readonly eff1 = effect(() => {
    const assistantStatus = this.assistantStatus();

    const classNames = Array.from(document.body.classList);
    const className = classNames.find(c => c.startsWith('assistant-status-'));

    if (className) {
      document.body.classList.remove(className);
    }

    document.body.classList.add(`assistant-status-${assistantStatus}`);

    setTimeout(() => {
      win.dispatchEvent(new Event('resize'));
    }, 250); // 250ms has to corelate with the animation length
  });

  @HostBinding('class') public get getHostClass() {
    return `assistant-status-${this.assistantStatus()}`;
  }

  public ngOnInit(): void {
    this.sitemuleAIService.init(this.el.nativeElement.querySelector('.assistant')).then(() => {
      this.sitemuleAIService.onMessageLinkClick((link: string) => {
        if (isSameDomain(link)) {
          this.router.navigateByUrl(getRelativePath(link));
        } else {
          window.open(link);
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.eff1.destroy();
  }

  public doSomething() {
    this.sitemuleAIService.expandSmall();
  }

  public addSuggestion(message: string) {
    const clonedSuggestions = [...this.suggestions()];
    const index = clonedSuggestions.indexOf(message);
    clonedSuggestions.splice(index, 1);
    this.sitemuleAIService.addSuggestedMessages(clonedSuggestions);
    this.sitemuleAIService.showChatView();
    this.sitemuleAIService.sendMessage(message);
  }

  public assistantMaskClick() {
    this.sitemuleAIService.showIcon();
  }
}
